/**
 * @generated SignedSource<<2415043ed7989e3e9dd0008cfdb98048>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type Environment = "PRODUCTION" | "TEST" | "%future added value";
export type NninConsentStrategy = "EPHEMERAL" | "REMEMBERED" | "%future added value";
export type NorwegianBankIdDefaultAssuranceLevel = "HIGH" | "SUBSTANTIAL" | "%future added value";
export type UpdateNorwegianBankIDInput = {
  clientId?: string | null;
  clientSecret?: string | null;
  defaultAssuranceLevel?: NorwegianBankIdDefaultAssuranceLevel | null;
  environment: Environment;
  nninConsentDuration?: number | null;
  nninConsentStrategy?: NninConsentStrategy | null;
  requestAddress?: boolean | null;
  requestEmail?: boolean | null;
  requestPhone?: boolean | null;
  requestSocialNo?: boolean | null;
  tenantId: string;
};
export type NOBankIDProviderMutation$variables = {
  input: UpdateNorwegianBankIDInput;
};
export type NOBankIDProviderMutation$data = {
  readonly updateNorwegianBankID: {
    readonly clientId: string | null;
    readonly defaultAssuranceLevel: NorwegianBankIdDefaultAssuranceLevel;
    readonly id: string;
    readonly isConfigured: boolean;
    readonly nninConsentDuration: number;
    readonly nninConsentStrategy: NninConsentStrategy;
    readonly requestAddress: boolean;
    readonly requestEmail: boolean;
    readonly requestPhone: boolean;
    readonly requestSocialNo: boolean;
  };
};
export type NOBankIDProviderMutation = {
  response: NOBankIDProviderMutation$data;
  variables: NOBankIDProviderMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "NorwegianBankID",
    "kind": "LinkedField",
    "name": "updateNorwegianBankID",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isConfigured",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "requestAddress",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "requestEmail",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "requestPhone",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "requestSocialNo",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "nninConsentStrategy",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "nninConsentDuration",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "defaultAssuranceLevel",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NOBankIDProviderMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NOBankIDProviderMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "70f74ca06c629d13a286f5a16a038b8d",
    "id": null,
    "metadata": {},
    "name": "NOBankIDProviderMutation",
    "operationKind": "mutation",
    "text": "mutation NOBankIDProviderMutation(\n  $input: UpdateNorwegianBankIDInput!\n) {\n  updateNorwegianBankID(input: $input) {\n    id\n    isConfigured\n    clientId\n    requestAddress\n    requestEmail\n    requestPhone\n    requestSocialNo\n    nninConsentStrategy\n    nninConsentDuration\n    defaultAssuranceLevel\n  }\n}\n"
  }
};
})();

(node as any).hash = "baddf3103c73538c9c78dd69c9f65f64";

export default node;
