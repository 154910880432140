import {Variables, GraphQLResponse} from 'relay-runtime';
import { AuthState } from './redux/authSlice';

export class AuthenticationError extends Error {
  constructor(m: string) {
    super(m);
    Object.setPrototypeOf(this, AuthenticationError.prototype);
  }
}

interface NetlifyResponse {
  errorMessage: string
}

function tryParseJSON<T>(input: string): T | null {
  try {
    return JSON.parse(input) as T;
  } catch (err) {
    return null;
  }
}

async function fetchGraphQL(query : string, variables : Variables, auth: AuthState) {
  const response = await fetch(`${process.env.FUNCTIONS_HOST || ''}/.netlify/functions/graphql`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${auth.access_token}, id_token ${auth.id_token}`
    },
    body: JSON.stringify({
      query,
      variables,
    }),
  });

  if (response.status === 401) {
    throw new AuthenticationError('Unauthenticated, please refresh your browser.');
  }

  const text = await response.text();
  const payload = tryParseJSON<GraphQLResponse | NetlifyResponse>(text);
  if (!payload) {
    throw new Error(`API returned non-JSON response: ${text}`);
  }

  if ("errorMessage" in payload) {
    throw new Error(`Netlify error: ${payload.errorMessage}`);
  }

  if ("errors" in payload && payload.errors) {
    throw new Error(payload.errors[0].message);
  }
  return payload;
}

export default fetchGraphQL;