/**
 * @generated SignedSource<<3e60b356bb607f274f03da2c9c867c88>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ApplicationScopeStrategy = "DYNAMIC" | "STATIC" | "%future added value";
export type Product = "Ageverification_DK" | "Signatures" | "Verify" | "%future added value";
export type CreateApplicationInput = {
  acrValues?: ReadonlyArray<string> | null;
  callbackUrls?: ReadonlyArray<string> | null;
  domainId: string;
  metadata?: ReadonlyArray<MetadataInput> | null;
  name: string;
  oauth2ClientSecretEnabled?: boolean | null;
  product: Product;
  realm: string;
  scopeStrategy?: ApplicationScopeStrategy | null;
  technology?: string | null;
  technologyGroup?: string | null;
};
export type MetadataInput = {
  key: string;
  value: string;
};
export type VerifyApplicationCreatorMutation$variables = {
  connections: ReadonlyArray<string>;
  input: CreateApplicationInput;
};
export type VerifyApplicationCreatorMutation$data = {
  readonly createApplication: {
    readonly application?: {
      readonly domain: {
        readonly name: string;
      };
      readonly id: string;
      readonly name: string;
      readonly realm: string;
      readonly " $fragmentSpreads": FragmentRefs<"GeneralSection_application" | "VerifyApplicationIntegrate_application">;
    };
    readonly applicationEdge?: {
      readonly cursor: string;
      readonly node: {
        readonly __typename: string;
        readonly domain: {
          readonly name: string;
        };
        readonly id: string;
        readonly name: string;
        readonly realm: string;
        readonly " $fragmentSpreads": FragmentRefs<"GeneralSection_application" | "VerifyApplicationIntegrate_application">;
      };
    };
    readonly clientSecret?: string | null;
  };
};
export type VerifyApplicationCreatorMutation = {
  response: VerifyApplicationCreatorMutation$data;
  variables: VerifyApplicationCreatorMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "connections"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "realm",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "Domain",
  "kind": "LinkedField",
  "name": "domain",
  "plural": false,
  "selections": [
    (v5/*: any*/)
  ],
  "storageKey": null
},
v8 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "GeneralSection_application"
},
v9 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "VerifyApplicationIntegrate_application"
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "clientSecret",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "Domain",
  "kind": "LinkedField",
  "name": "domain",
  "plural": false,
  "selections": [
    (v5/*: any*/),
    (v3/*: any*/)
  ],
  "storageKey": null
},
v12 = {
  "kind": "TypeDiscriminator",
  "abstractKey": "__isApplication"
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "errorStrategy",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "scopeStrategy",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "callbackUrls",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "technology",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "technologyGroup",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "concreteType": "Tenant",
  "kind": "LinkedField",
  "name": "tenant",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shortTenantId",
      "storageKey": null
    },
    (v3/*: any*/)
  ],
  "storageKey": null
},
v19 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ApplicationWsFederationSettings",
      "kind": "LinkedField",
      "name": "wsfederation",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "enabled",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "OIDCApplication",
  "abstractKey": "__isOIDCApplication"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "VerifyApplicationCreatorMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "createApplication",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ApplicationEdge",
                "kind": "LinkedField",
                "name": "applicationEdge",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "application",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v7/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/)
                ],
                "storageKey": null
              },
              (v10/*: any*/)
            ],
            "type": "CreateVerifyApplicationOutput",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "VerifyApplicationCreatorMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "createApplication",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ApplicationEdge",
                "kind": "LinkedField",
                "name": "applicationEdge",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v13/*: any*/),
                      (v14/*: any*/),
                      (v15/*: any*/),
                      (v16/*: any*/),
                      (v17/*: any*/),
                      (v18/*: any*/),
                      (v19/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "filters": null,
                "handle": "appendEdge",
                "key": "",
                "kind": "LinkedHandle",
                "name": "applicationEdge",
                "handleArgs": [
                  {
                    "kind": "Variable",
                    "name": "connections",
                    "variableName": "connections"
                  }
                ]
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "application",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v3/*: any*/),
                  (v11/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v19/*: any*/)
                ],
                "storageKey": null
              },
              (v10/*: any*/)
            ],
            "type": "CreateVerifyApplicationOutput",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "85bb9350250914cdce9503275347f108",
    "id": null,
    "metadata": {},
    "name": "VerifyApplicationCreatorMutation",
    "operationKind": "mutation",
    "text": "mutation VerifyApplicationCreatorMutation(\n  $input: CreateApplicationInput!\n) {\n  createApplication(input: $input) {\n    __typename\n    ... on CreateVerifyApplicationOutput {\n      applicationEdge {\n        cursor\n        node {\n          id\n          __typename\n          name\n          realm\n          domain {\n            name\n            id\n          }\n          ...GeneralSection_application\n          ...VerifyApplicationIntegrate_application\n        }\n      }\n      application {\n        __typename\n        id\n        domain {\n          name\n          id\n        }\n        name\n        realm\n        ...GeneralSection_application\n        ...VerifyApplicationIntegrate_application\n      }\n      clientSecret\n    }\n  }\n}\n\nfragment ApplicationRealmValidation_application on Application {\n  __isApplication: __typename\n  id\n  realm\n}\n\nfragment ApplicationTechnologySelector_application on Application {\n  __isApplication: __typename\n  id\n  technology\n}\n\nfragment GeneralSection_application on Application {\n  __isApplication: __typename\n  __typename\n  id\n  name\n  errorStrategy\n  scopeStrategy\n  callbackUrls\n  ... on VerifyApplication {\n    realm\n  }\n  ... on AgeverificationApplication {\n    realm\n  }\n  ... on OIDCApplication {\n    __isOIDCApplication: __typename\n    wsfederation {\n      enabled\n    }\n  }\n  domain {\n    id\n    name\n  }\n  ...ApplicationRealmValidation_application\n}\n\nfragment VerifyApplicationIntegrate_application on Application {\n  __isApplication: __typename\n  id\n  technology\n  technologyGroup\n  realm\n  domain {\n    name\n    id\n  }\n  tenant {\n    shortTenantId\n    id\n  }\n  ...ApplicationTechnologySelector_application\n}\n"
  }
};
})();

(node as any).hash = "2f7385d9d67c1e28be2037a2e616521a";

export default node;
