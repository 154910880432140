/**
 * @generated SignedSource<<45277284cb1fed83becd02f84f6baf9d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Currency = "DKK" | "EUR" | "NOK" | "%future added value";
export type Interval = "MONTH" | "YEAR" | "%future added value";
export type Product = "Ageverification_DK" | "Signatures" | "Verify" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type SubscriptionPlanModal_plan$data = {
  readonly currency: Currency;
  readonly id: string;
  readonly interval: Interval;
  readonly legacyId: string;
  readonly name: string;
  readonly product: Product;
  readonly subscriptionPrice: number;
  readonly " $fragmentType": "SubscriptionPlanModal_plan";
};
export type SubscriptionPlanModal_plan$key = {
  readonly " $data"?: SubscriptionPlanModal_plan$data;
  readonly " $fragmentSpreads": FragmentRefs<"SubscriptionPlanModal_plan">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SubscriptionPlanModal_plan",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "legacyId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currency",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "interval",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "product",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "subscriptionPrice",
      "storageKey": null
    }
  ],
  "type": "Plan",
  "abstractKey": null
};

(node as any).hash = "2b63aa7da70b70bbb87b775b5430a319";

export default node;
