/**
 * @generated SignedSource<<560d230f202e75e31fa96a6c6dd34d76>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ApplicationErrorStrategy = "LEGACY" | "PROTOCOL" | "%future added value";
export type ApplicationScopeStrategy = "DYNAMIC" | "STATIC" | "%future added value";
export type ClaimPropertyFormat = "COMPACT" | "DEFAULT" | "NAMESPACED" | "%future added value";
export type NullClaimValues = "ALLOW" | "REMOVE" | "%future added value";
export type UserInfoResponseStrategy = "FROM_TOKEN_ENDPOINT" | "PLAIN_JSON" | "SIGNED_JWT" | "%future added value";
export type ViewVersion = "INITIAL" | "UNIFIED" | "%future added value";
export type UpdateApplicationInput = {
  acrValues?: ReadonlyArray<string> | null;
  android?: VerifyApplicationAndroidInput | null;
  applicationId: string;
  callbackUrls?: ReadonlyArray<string> | null;
  clientJwks?: ApplicationJwksInput | null;
  errorStrategy?: ApplicationErrorStrategy | null;
  frameOrigin?: string | null;
  iOS?: VerifyApplicationIOSInput | null;
  metadata?: ReadonlyArray<MetadataInput> | null;
  name?: string | null;
  oauth2?: ApplicationOAuth2SettingsInput | null;
  oauth2ClientSecretEnabled?: boolean | null;
  realm?: string | null;
  scopeStrategy?: ApplicationScopeStrategy | null;
  sessionLifetime?: string | null;
  technology?: string | null;
  technologyGroup?: string | null;
  tokenLifetime?: string | null;
  ui?: UpdateApplicationUIInput | null;
  wsfederation?: ApplicationWsFederationSettingsInput | null;
};
export type UpdateApplicationUIInput = {
  cssClass?: string | null;
  javascript?: string | null;
  stylesheet?: string | null;
  viewVersion?: ViewVersion | null;
};
export type ApplicationOAuth2SettingsInput = {
  callbackOnLocationHash?: boolean | null;
  claimPropertyFormat?: ClaimPropertyFormat | null;
  nullClaimValues?: NullClaimValues | null;
  userInfoResponseStrategy?: UserInfoResponseStrategy | null;
};
export type ApplicationWsFederationSettingsInput = {
  enabled?: boolean | null;
};
export type ApplicationJwksInput = {
  static?: string | null;
  url?: string | null;
};
export type MetadataInput = {
  key: string;
  value: string;
};
export type VerifyApplicationAndroidInput = {
  packageName: string;
  sha256Fingerprints: ReadonlyArray<string>;
};
export type VerifyApplicationIOSInput = {
  bundleId: string;
  teamId: string;
};
export type AdvancedOptionsSectionMutation$variables = {
  input: UpdateApplicationInput;
};
export type AdvancedOptionsSectionMutation$data = {
  readonly updateApplication: {
    readonly application: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"AdvancedOptionsSection_application">;
    };
  };
};
export type AdvancedOptionsSectionMutation = {
  response: AdvancedOptionsSectionMutation$data;
  variables: AdvancedOptionsSectionMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AdvancedOptionsSectionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateApplicationOutput",
        "kind": "LinkedField",
        "name": "updateApplication",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "application",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "AdvancedOptionsSection_application"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AdvancedOptionsSectionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateApplicationOutput",
        "kind": "LinkedField",
        "name": "updateApplication",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "application",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "frameOrigin",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "sessionLifetime",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "tokenLifetime",
                    "storageKey": null
                  }
                ],
                "type": "VerifyApplication",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "af7d7e6ce62e8f8396a821ee10f3467a",
    "id": null,
    "metadata": {},
    "name": "AdvancedOptionsSectionMutation",
    "operationKind": "mutation",
    "text": "mutation AdvancedOptionsSectionMutation(\n  $input: UpdateApplicationInput!\n) {\n  updateApplication(input: $input) {\n    application {\n      __typename\n      id\n      ...AdvancedOptionsSection_application\n    }\n  }\n}\n\nfragment AdvancedOptionsSection_application on VerifyApplication {\n  id\n  frameOrigin\n  sessionLifetime\n  tokenLifetime\n}\n"
  }
};
})();

(node as any).hash = "b3a84477c2863cf88d31fbd7ca478c5d";

export default node;
