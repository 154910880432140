import React from 'react';
import cx from 'classnames';
import useField from '../useField';
import HelpText from '../HelpText';
import styles from '../FormFields.module.css';

export interface SelectProps<T> extends Omit<React.SelectHTMLAttributes<HTMLSelectElement>, 'name'> {
  name: T extends never ? string : keyof T;
  label?: string;
  className?: string;
  onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  children?: React.ReactNode;
  helpText?: React.ReactNode;
}

function className<T>(props: SelectProps<T>) {
  return cx(styles[`input-field-container`], {
    [styles['has-label']]: props.label ? true : false,
    [styles['disabled']]: props.disabled ? true : false,
  });
}

export default function Select<T = never>(props: SelectProps<T>) {
  const fieldResult = useField(props.name as string);
  const [field] = fieldResult ?? [];

  const { helpText, ...rest } = props;

  const select = (
    <select
      {...field}
      {...rest}
      className={`${styles['select']} !border-none`}
      id={props.id}
      name={props.name as string}
      onChange={props.onChange ?? field?.onChange}
    >
      {props.children}
    </select>
  );

  return (
    <div>
      <label htmlFor={props.id} className={cx(className(props), props.className)}>
        {props.label ? (
          <div className={`${styles['input-wrapper']} ${styles['select-wrapper']}`}>
            <span className={`${styles['input-label']} ${styles['select-label']}`}>{props.label}</span>
            {select}
          </div>
        ) : (
          <>{select}</>
        )}
      </label>
      {helpText && <HelpText>{helpText}</HelpText>}
    </div>
  );
}
