import React, {useEffect, useMemo, useState} from 'react';
import cx from 'classnames';

import { PreloadedQuery, usePreloadedQuery, useQueryLoader } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import { Currency, Interval, PlanSelection_Query, Product } from './__generated__/PlanSelection_Query.graphql';
import GraphQLErrorBoundary from '@app/components/GraphQLErrorBoundary';
import Button from '@app/components/Button/Button';
import { productName, transactionName } from '@app/screens/BillingScreen/utils';


const PlansQuery = graphql`
  query PlanSelection_Query($interval: Interval!, $currency: Currency!) {
    plans(interval: $interval, currency: $currency) {
      id
      legacyId
      name
      productName
      volume
      subscriptionPrice
      unitPrice
      currency
      interval
      product
      eidUsageIncluded
    }
  }
`;

type Plan = PlanSelection_Query["response"]["plans"][0];

export type PlanChoice = {
  id: string
  product: Product
  legacyId: string
  name: string
  currency: Currency
  interval: Interval
  subscriptionPrice: number
};

interface SingleProductProps {
  product: Product,
  customerInterval?: Interval,
  customerCurrency?: Currency,
  onSelect: (choice: PlanChoice | null) => void,
  selected?: PlanChoice
}
interface MultipleProductProps {
  products: Product[],
  customerInterval?: Interval,
  customerCurrency?: Currency,
  onSelect: (choices: PlanChoice[]) => void,
  selected?: PlanChoice[]
}

type Props = SingleProductProps | MultipleProductProps;

export default function PlanSelection(props: Props) {
  const {customerCurrency, customerInterval} = props;

  const [queryReference, loadQuery] = useQueryLoader<PlanSelection_Query>(
    PlansQuery,
    null
  );

  const selected = Array.isArray(props.selected) ? props.selected : props.selected ? [props.selected] : [];
  const [currency, setCurrency] = useState<Currency>(customerCurrency ?? selected[0]?.currency ?? 'DKK');
  const [interval, setInterval] = useState<Interval>(customerInterval ?? selected[0]?.interval ?? 'MONTH');

  useEffect(() => {
    loadQuery({
      currency,
      interval
    });
  }, [currency, interval]);

  const defaultPeriodClass = `flex items-center justify-center py-2 px-8 ${customerInterval ? 'bg-gray-200 text-white cursor-not-allowed' : 'cursor-pointer'}`;
  const selectedPeriodClass = "!bg-primary-900 text-white";

  const handleInterval = (interval: Interval) => {
    if (customerInterval) return;
    setInterval(interval);
  }

  const handleCurrency = (currency: Currency) => {
    setCurrency(currency);
  }

  return (
    <React.Fragment>
      <div className="flex flex-row justify-between">
        <div className="hidden lg:block">&nbsp;</div>
        <div className="rounded-full flex flex-row border border-primary-900 ">
          <div className={cx(defaultPeriodClass, 'rounded-l-full', {[selectedPeriodClass]: interval === 'MONTH'})} onClick={() => handleInterval('MONTH')}>
            Monthly
          </div>
          <div data-test-id="select_interval_year" className={cx(defaultPeriodClass, 'rounded-r-full', {[selectedPeriodClass]: interval === 'YEAR'})} onClick={() => handleInterval('YEAR')}>
            Annual - 10% off
          </div>
        </div>
        <div>
          <select className="form-control" data-test-id={`select_currency`} value={currency} onChange={(event) => handleCurrency(event.target.value as Currency)} disabled={customerCurrency != null ? true : false}>
            {!customerCurrency ?
            (
              <React.Fragment>
                <option value="DKK">Prices in DKK</option>
                <option value="EUR">Prices in EUR</option>
                <option value="NOK">Prices in NOK</option>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <option value={customerCurrency.toLowerCase()}>Prices in {customerCurrency.toUpperCase()}</option>
              </React.Fragment>
            )
            }
          </select>
        </div>
      </div>
      <div className="mt-8 mb-8 text-right">
        Other needs? <a href="mailto:sales@criipto.com" className="text-primary !underline">Contact us</a>
      </div>
      {queryReference ? (
        <GraphQLErrorBoundary>
          <Products {...props} queryReference={queryReference} currency={currency} interval={interval} />
        </GraphQLErrorBoundary>
      ) : null}
    </React.Fragment>
  );
}

type ProductsProps = {
  currency: Currency
  interval: Interval
  queryReference: PreloadedQuery<PlanSelection_Query>,
};
function Products(
  props:
    (Omit<SingleProductProps, 'customerCurrency' | 'customerInterval'> & ProductsProps) |
    (Omit<MultipleProductProps, 'customerCurrency' | 'customerInterval'> & ProductsProps)
  ) {
  const {currency, interval} = props;
  const products = "products" in props ? props.products : [props.product];
  const [showProducts, setShowProducts] = useState([products[0]]);
  const selected = Array.isArray(props.selected) ? props.selected : props.selected ? [props.selected] : [];
  const [selectedPlans, setSelectedPlans] = useState<PlanChoice[]>(selected);

  const data = usePreloadedQuery<PlanSelection_Query>(PlansQuery, props.queryReference);
  const eligibleProducts = useMemo(() => data.plans.map(s => s.product), [data]);


  const handlePlan = (plan: Plan, product: Product) => {
    const selected =
      selectedPlans
        .filter(s => s.product !== product).concat([plan])
        .filter(item => showProducts.includes(item.product) && eligibleProducts.includes(item.product));
    setSelectedPlans(selected);
    if ("products" in props) {
      props.onSelect(selected);
    } else {
      props.onSelect(selected.find(s => s.product === product)!);
    }
  };

  const handleShowProduct = (product: Product, checked: boolean) => {
    const products = checked ? showProducts.concat([product]) : showProducts.filter(s => s !== product);
    setShowProducts(products);
    const selected = selectedPlans.filter(s => products.includes(s.product));
    if ("products" in props) {
      props.onSelect(selected);
    } else {
      props.onSelect(selected.find(s => s.product === product)!);
    }
  }

  useEffect(() => {
    const selected = selectedPlans.map(item => {
      return {
        ...item,
        currency,
        interval
      }
    }).filter(item => showProducts.includes(item.product) && eligibleProducts.includes(item.product));
    setSelectedPlans(selected);
    if ("products" in props) {
      props.onSelect(selected);
    } else {
      props.onSelect(selected[0]);
    }
  }, [currency, interval, eligibleProducts]);

  return (
    <>
      {products.filter(s => eligibleProducts.includes(s)).map(product => (
        <div>
          <label className="flex flex-row gap-2 items-center cursor-pointer">
            <strong>{productName(product)}</strong>
            <input
              type="checkbox"
              checked={showProducts.includes(product)}
              onChange={event => handleShowProduct(product, event.target.checked)}
              data-test-id={`plans_product_${product}_toggle`}
            />
          </label>
          {showProducts.includes(product) ? (
            <div className="mt-8 mb-8 flex flex-row gap-[20px]" key={product}>
              <Plans
                product={product}
                queryReference={props.queryReference}
                handlePlan={plan => handlePlan(plan, product)}
                selectedPlanId={selectedPlans.find(s => s.product === product)?.id}
              />
            </div>
          ) : null}
        </div>
      ))}
    </>
  );
}

function Plans(props: {
  product: Product,
  queryReference: PreloadedQuery<PlanSelection_Query>,
  handlePlan: (plan: Plan) => void,
  selectedPlanId?: string,
}) {
  const data = usePreloadedQuery<PlanSelection_Query>(PlansQuery, props.queryReference);
  const plans = Array.from(data.plans).filter(s => s.product === props.product);
  return (
    <React.Fragment>
      {plans.sort((a, b) => a.volume - b.volume).map(plan => (
        <div key={plan.id} className="flex-1 rounded-lg border border-primary-900 gap-[16px] flex flex-col cursor-pointer" style={{background: '#F2F1ED'}} onClick={() => props.handlePlan(plan)}>
          <h2 className="border-b border-primary-900 border-gray-600 text-3xl p-6 text-center font-bold">
            {plan.productName} - {plan.name}
          </h2>
          <h3 className="text-4xl text-center">
            {plan.currency.toUpperCase()} <strong>{plan.subscriptionPrice.toLocaleString()}</strong> per {plan.interval.toLowerCase()}
          </h3>
          <p className="!m-0 text-2xl px-6">
            {plan.volume.toLocaleString()} {transactionName(plan.product, true)} monthly
          </p>
          <p className="!m-0 text-xl px-6">
            {plan.currency.toUpperCase()} {plan.unitPrice} per {plan.product === 'Signatures' ? 'signature' : 'transaction'} if you use more than {plan.volume.toLocaleString()} {plan.product === 'Signatures' ? 'signatures' : 'transactions'}.
          </p>
          {plan.eidUsageIncluded ? (
            <div className="!m-0 text-lg px-6">Fees for underlying national/bank e-ID included</div>
          ) : (
            <a className="!m-0 text-lg px-6" href="https://www.criipto.com/pricing#fees-block" target="_blank">Fees for underlying national/bank e-ID billed separately</a>
          )}

          {plan.id === props.selectedPlanId ? <i className="fa fa-check self-center justify-self-center" /> : (
            <Button data-test-id={`plan_select_${plan.legacyId}`} variant="default" className="ml-6 mr-6 mb-6 self-center max-w-[200px]" autoWidth>
              Choose
            </Button>
          )}
        </div>
      ))}
    </React.Fragment>
  );
}