import React, {useState} from 'react';

import { useFragment } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import { singleton as config } from '@app/config';
import { translate } from '@app/i18n';
import { Switch } from '@app/components/Form';
import InputField  from '@app/components/FormFields/InputField/InputField';
import { ApplicationRelayForm } from '../../ApplicationForm/ApplicationForm';
import useMutation from '@app/hooks/useMutation';
import { GeneralSectionMutation, ApplicationErrorStrategy, ApplicationScopeStrategy } from './__generated__/GeneralSectionMutation.graphql';
import { GeneralSection_application$key } from './__generated__/GeneralSection_application.graphql';
import ApplicationNameField from '../ApplicationNameField';
import CopyValueButton from '@app/components/CopyValueButton/CopyValueButton';
import ApplicationRealmValidation from '../ApplicationRealmValidation';
import ReturnURLs from '../ReturnURLs';
import QRReady from '../QRReady';
import { useHistory } from 'react-router-dom';
import { useDispatch } from '@app/redux';
import { IS_DEVELOPER_MODE } from '@app/constants';
import WsFederationConfig from './WsFederationConfig';

type Props =  {
  application: GeneralSection_application$key
  parentUrl: string
}

type VerifyValues = {
  name: string
  realm: string
  errorStrategy: ApplicationErrorStrategy
  scopeStrategy: ApplicationScopeStrategy
  returnUrls: string[]
  wsfederation: {
    enabled: boolean
  }
}
type SignaturesValues = {
  name: string
  errorStrategy: ApplicationErrorStrategy
  scopeStrategy: ApplicationScopeStrategy
  returnUrls: string[]
}
type Values = VerifyValues | SignaturesValues;

export default function GeneralSection(props: Props) {
  const [realmValid, setRealmValid] = useState(true);
  const history = useHistory();
  const dispatch = useDispatch();

  const application = useFragment(graphql`
    fragment GeneralSection_application on Application {
      __typename
      id
      name

      errorStrategy
      scopeStrategy
      callbackUrls

      ... on VerifyApplication {
        realm
      }
      ... on AgeverificationApplication {
        realm
      }

      ... on OIDCApplication {
        wsfederation {
          enabled
        }
      }

      domain {
        id
        name
      }
      ... ApplicationRealmValidation_application
    }
  `, props.application);

  const initialValues : Values = {
    name: application.name,
    realm: application.realm,
    errorStrategy: application.errorStrategy,
    scopeStrategy: application.scopeStrategy,
    returnUrls: application.callbackUrls.slice(0),
    wsfederation: {
      enabled: application.wsfederation?.enabled ?? true
    }
  };

  const [mutationExecutor, mutationState] = useMutation<GeneralSectionMutation>(graphql`
    mutation GeneralSectionMutation($input: UpdateApplicationInput!) {
      updateApplication(input: $input) {
        application {
          id
          domain {
            id
            name
          }
          realm
          ... GeneralSection_application
        }
      }
    }
  `);

  return (
    <ApplicationRelayForm
      formSuccessMessage="Application settings updated"
      values={initialValues}
      valid={realmValid}
      onSubmit={async values => {
        const response = await mutationExecutor.executePromise({
          input: {
            applicationId: application.id,
            name: values.name,
            realm: "realm" in values ? values.realm : undefined,
            callbackUrls: values.returnUrls,
            scopeStrategy: "scopeStrategy" in values ? values.scopeStrategy : undefined,
            errorStrategy: values.errorStrategy,
            wsfederation: "wsfederation" in values ? values.wsfederation : undefined
          }
        });

        history.replace(`${props.parentUrl}/${response.updateApplication.application!.id}`)
      }}
    >

      <div className='flex flex-col gap-[12px]'>
          <ApplicationNameField />

          <InputField
            name="Choose_domain"
            label={translate('LABEL_CHOOSE_DOMAIN')}
            value={application.domain.name}
            disabled
            button={<CopyValueButton value={application.domain.name} tooltip="Copy domain" variant='primary'/>}
          />

          {"realm" in application && (
            <InputField<Values>
              type="text"
              label={translate('LABEL_CLIENT_ID')}
              name="realm"
              required
              placeholder={translate('LABEL_CLIENT_ID')}
              iconRight={
                <React.Suspense fallback={<i className="fa fa-spinner fa-pulse" />}>
                  <ApplicationRealmValidation domainId={application.domain.id} application={application} onValid={setRealmValid} />
                </React.Suspense>
              }
            />
          )}

          <ReturnURLs />

          {/* temporary div to visually group the fields and the switches: remove after new Switch design is implemented. */}
          <div className='mt-[17px]'></div>

          <QRReady
            domain={application.domain.name}
          />

          {(IS_DEVELOPER_MODE || application.__typename === 'VerifyApplication') ? (
            <Switch
              name="errorStrategy"
              label={translate('LABEL_APPLICATION_ERRORSTRATEGY')}
              off={"LEGACY"} on="PROTOCOL"
              help={<small className="help-text">{translate('INFO_APPLICATION_ERRORSTRATEGY')}</small>}
            />
          ) : null}

          <Switch
            name="scopeStrategy"
            label={translate('LABEL_APPLICATION_SCOPESTRATEGY')}
            off="STATIC" on="DYNAMIC"
            help={(
              <small className="help-text">
                Dynamic scopes let you define the data you need per request.&nbsp;
                <a href="https://docs.criipto.com/verify/getting-started/oidc-intro/#the-scope-parameter" target="_blank">Read more in our documentation</a>.
              </small>
            )}
          />

          {application.__typename === 'VerifyApplication' ? (
            <WsFederationConfig />
          ) : null}
      </div>
    </ApplicationRelayForm>
  )
}