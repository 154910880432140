/**
 * @generated SignedSource<<deb89f76b8017dfd0354eeb42db63e5c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Currency = "DKK" | "EUR" | "NOK" | "%future added value";
export type Interval = "MONTH" | "YEAR" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type SubscriptionPlanModal_subscription$data = {
  readonly currency: Currency;
  readonly id: string;
  readonly interval: Interval;
  readonly isCancelling: boolean;
  readonly products: ReadonlyArray<{
    readonly plan: {
      readonly id: string;
    } | null;
  }>;
  readonly " $fragmentType": "SubscriptionPlanModal_subscription";
};
export type SubscriptionPlanModal_subscription$key = {
  readonly " $data"?: SubscriptionPlanModal_subscription$data;
  readonly " $fragmentSpreads": FragmentRefs<"SubscriptionPlanModal_subscription">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SubscriptionPlanModal_subscription",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isCancelling",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currency",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "interval",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SubscriptionProduct",
      "kind": "LinkedField",
      "name": "products",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Plan",
          "kind": "LinkedField",
          "name": "plan",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "BillingSubscription",
  "abstractKey": null
};
})();

(node as any).hash = "e3fbaecb912610ce2e6ebd5f9d423b1c";

export default node;
