import React, { useReducer, useState } from 'react';
import useMutation from '@app/hooks/useMutation';
import graphql from 'babel-plugin-relay/macro';

import { ConfirmModal } from '@app/components/Modal/Modal';
import Textarea from '@app/components/FormFields/Textarea/Textarea';
import Alert from '@app/components/Alert/Alert';
import { EnvironmentFeatureToggleMutation } from './__generated__/EnvironmentFeatureToggleMutation.graphql';
import { useFragment } from 'react-relay';
import { EnvironmentFeatureToggle_tenant$key, EnvironmentFeatureToggle_tenant$data } from './__generated__/EnvironmentFeatureToggle_tenant.graphql';
import { upperFirst } from 'lodash';
import { StandaloneSwitch } from '@app/components/Form/Form';

interface Props {
  feature: EnvironmentFeatureToggleMutation["variables"]["input"]["feature"] | "%future added value"
  environment: EnvironmentFeatureToggleMutation["variables"]["input"]["environment"]
  tenant: EnvironmentFeatureToggle_tenant$key
}

export default function EnvironmentFeatureToggle(props: Props) {
  const [showConfirm, toggleConfirm] = useReducer((value) => !value, false);

  const tenant = useFragment(graphql`
    fragment EnvironmentFeatureToggle_tenant on AdminTenant {
      id

      features {
        test {
          DK_PocesAddress
          FREE_CUSTOM_DOMAINS
          EXTENSIONS
        }
        production {
          DK_PocesAddress
          FREE_CUSTOM_DOMAINS
          EXTENSIONS
        }
      }
    }
  `, props.tenant);

  if (props.feature === '%future added value') return null;

  const [enabled, setEnabled] = useState(tenant.features[props.environment.toLowerCase() as keyof EnvironmentFeatureToggle_tenant$data["features"]][props.feature]);
  const [note, setNote] = useState('');

  const [{executePromise}, state] = useMutation<EnvironmentFeatureToggleMutation>(graphql`
    mutation EnvironmentFeatureToggleMutation($input: ToggleEnvironmentFeatureInput!) {
      admin_toggleEnvironmentFeature(input: $input) {
        tenant {
          id

          ...EnvironmentFeatureToggle_tenant
        }
      }
    }
  `);

  const handleChange = (value: boolean) => {
    setEnabled(value);
    toggleConfirm();
  }

  const handleConfirm = () => {
    return executePromise({
      input: {
        tenantId: tenant.id,
        feature: props.feature as EnvironmentFeatureToggleMutation["variables"]["input"]["feature"],
        environment: props.environment,
        enabled,
        note: note?.length ? note : null
      }
    }).then(() => {
      toggleConfirm()
    }).catch(() => {});
  }

  const prompt = `${enabled ? 'enable' : 'disable'} ${props.feature}`

  return (
    <React.Fragment>
      <StandaloneSwitch
        label={`${props.feature} enabled`}
        value={tenant.features[props.environment.toLowerCase() as keyof EnvironmentFeatureToggle_tenant$data["features"]][props.feature]}
        onChange={handleChange}
      />

      <ConfirmModal
        open={showConfirm}
        onCancel={() => toggleConfirm()}
        onConfirm={handleConfirm}
        confirmText={upperFirst(prompt)}
        confirmVariant="primary"
        data-test-id="confirm-modal"
      >
        <div>
          <h2>{upperFirst(prompt)}</h2>
          <p>Are you sure you want to {prompt} in {props.environment}?</p>
        </div>

        {props.feature === 'DK_PocesAddress' && props.environment === 'PRODUCTION' ? (
          <p>Please make sure the customer has signed a DPA before enabling this feature in production.</p>
        ) : null}

        <Textarea className='mt-[15px] !h-[100px] resize-none' name='note-text' label='Note:' value={note} onChange={event => setNote(event.target.value)} />

        {state.error && (
          <Alert variant="error" className="mt-[15px]" title="An error occurred" message={state.error.message} />
        )}
      </ConfirmModal>
    </React.Fragment>
  );
}