import React from 'react';
import { useField } from 'formik';

import { Switch } from '@app/components/Form/Form';

import { ApplicationWsFederationSettingsInput } from './__generated__/OIDCSectionMutation.graphql';
import HelpText from '@app/components/FormFields/HelpText';

export default function WsFederationConfig() {
  const [{value}, , {setValue}] = useField<ApplicationWsFederationSettingsInput>('wsfederation');

  const handleValue = (key: keyof ApplicationWsFederationSettingsInput, newValue: any) => {
    setValue({
      ...value,
      [key]: newValue
    });
  }

  return (
    <div className="mt-[20px]">
      <Switch<ApplicationWsFederationSettingsInput>
        name="enabled"
        label="Enable WS Federation"
        value={value.enabled ?? false}
        onChange={(value: boolean) => handleValue('enabled', value)}
      />
      <HelpText>WS Federation is a deprecated protocol, we suggest disabling it if you do not use it.</HelpText>
    </div>
  )
}