/**
 * @generated SignedSource<<0ba901808f69bc6e392f4dd989eff21d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EnvironmentFeatureToggle_tenant$data = {
  readonly features: {
    readonly production: {
      readonly DK_PocesAddress: boolean;
      readonly EXTENSIONS: boolean;
      readonly FREE_CUSTOM_DOMAINS: boolean;
    };
    readonly test: {
      readonly DK_PocesAddress: boolean;
      readonly EXTENSIONS: boolean;
      readonly FREE_CUSTOM_DOMAINS: boolean;
    };
  };
  readonly id: string;
  readonly " $fragmentType": "EnvironmentFeatureToggle_tenant";
};
export type EnvironmentFeatureToggle_tenant$key = {
  readonly " $data"?: EnvironmentFeatureToggle_tenant$data;
  readonly " $fragmentSpreads": FragmentRefs<"EnvironmentFeatureToggle_tenant">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "DK_PocesAddress",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "FREE_CUSTOM_DOMAINS",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "EXTENSIONS",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EnvironmentFeatureToggle_tenant",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AdminTenantFeatures",
      "kind": "LinkedField",
      "name": "features",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AdminTenantEnvironmentFeatures",
          "kind": "LinkedField",
          "name": "test",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AdminTenantEnvironmentFeatures",
          "kind": "LinkedField",
          "name": "production",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "AdminTenant",
  "abstractKey": null
};
})();

(node as any).hash = "f64e163c50a226b04e656967f491d1d0";

export default node;
